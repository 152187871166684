import styled from 'styled-components'

const Container = styled.div`
    // width: 70%;
    // margin: 0 auto;
`

const Bar = styled.div`
    height: 3px;
    background: ${props => props.color ? '#FD5135' : '#D8D8D8'};
    width: 80%;
    margin: 30px auto ${props => props.color ? '' : '15px'};
`
const Ball = styled.div`
    height: 12px;
    width: 12px;
    border-radius: 100%;
    position: relative;
    background: ${props => props.color ? '#FD5135' : '#D8D8D8'};
    top: -5px;
    float: left;
    ${props => props.posEnd && `
        float: right;
    `}
`

const Value = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: relative;
    top: -11px;
    background: #FD5135;
    left: calc(${props => props.progress * 100}% - 11px);
`

const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    span {
        font-family: Gilroy-Regular;
        font-size: 18px;
        line-height: 30px;
        color: #0B0031;
    }
`

export default function Scale({ from, to, progress, ...props }) {
    return <Container>
        <Bar color={!progress}>
            <Ball posStart={true} color={!progress} />
            <Ball posEnd={true} color={!progress} />    
            { progress ? <Value progress={progress} /> : null }
        </Bar>
        <LabelContainer>
            <span>{from}</span>
            <span>{to}</span>
        </LabelContainer>
    </Container> 
}