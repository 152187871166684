import styled from 'styled-components'
import { InfoBubble } from "../../../icons"

const Option = styled.div`
    cursor: pointer;
    display: flex;
    padding: 10px 0;
    color: white;
    ${props => props.disabled && `
        opacity: 0.5;
    `}
`

const Color = styled.span`
    display: inline-block;
    border-radius: 100%;
    border: 2px;
    height: 17px;
    width: 17px;
    ${props => props.selected && `
        background: ${props.color || 'white'};
    `}
    border-width: ${props => props.color ? '2px' : '1px' };
    border-style: solid;
    border-color: ${props => (props.color || 'white')};
    transition: background-color .15s;
    margin-right: 25px;
`

const Label = styled.span`
    margin-right: 15px;
    font-size: 20px;
`

export default function FilterOption({ label, color, selected, disabled, tooltip, onInfo, ...props }) {
    return (
        <Option disabled={disabled}>
            {color 
            ? <Color color={disabled ? 'grey' : color} selected={selected} />
            : <Color selected={selected} />
            }
            <Label>{label}</Label>
            { tooltip
            ? <span onClick={onInfo}><InfoBubble  /></span>
            : null 
            }
        </Option>
    )
}