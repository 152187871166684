export default function filterForPrefix(data, prefix) {
    return data.map(row => {
        const entries = Object.entries(row)
        const obj = {}
    
        entries
            .map(([key, value]) => {
                if (!key.includes('_')) return [key, value]
                if (!key.includes(prefix)) return undefined
                const [, component] = key.split('_')
                return [component, value]
            })
            .filter(a => a)
            .map(([key, value]) => obj[key] = value)
        
        return obj
    })
}