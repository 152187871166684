import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import FilterOverlay from './Filter'
import DesignerOverlay from './Designer'
import AxisOverlay from './Axis'

const ScrollBox = styled.div`
    width: 100vw;
    max-width: 100vw;
    height: ${props => props.height}px;
    max-height: ${props => props.height}px;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
    left: 0;
    display: flex;
    z-index: 9999;
    ${props => !props.open && `
        pointer-events: none;
    `}
`

const Container = styled.div`
    position: relative;
    left: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    transition: left .25s;
    ${props => props.open && `
        left: 0;
    `}
`
const Blackout = styled.div`
    width: 30%;
    height: 100%;
    cursor: pointer;
`
const Content = styled.div`
    width: ${props => props.isMobile ? '100%' : '80%' };
    position: relative;
    height: ${props => props.height}px;
    background: white;
    h2 {
        font-size: 30px;
        color: #FD5135;
        font-family: Gilroy-bold;
    }
    p {
        font-size: 20px;
        line-height: 25px;
        color: #0B0031;
        font-weight: bold;
        margin: 50px 0;
        font-family: Gilroy-Regular;
    }
    em {
        font-size: 20px;
        line-height: 23px; 
        font-family: Gilroy-Regular;     
    }
`

const CloseButton = styled.span`
    font-size: 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 50px;
    top: 50px;
    ${props => props.isMobile && `
        right: 20px;
        top: 30px;
    `}
    user-select: none;
    cursor: pointer;
    color: #07003A;
`


export default function Overlay({ containerRef, open, content, type, onClose, children, ...props }) {
    const [isOpen, setIsOpen] = useState(open)

    const closeOverlay = () => {
        setIsOpen(false)
        onClose()
    }

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const containerHeight = containerRef.current?.getBoundingClientRect().height || 0

    const contentRef = useRef()
    const contentHeight = Math.max(contentRef.current?.scrollHeight, containerHeight) || 0

    const contentBlock = ((type, content, ref) => {
        switch (type) {
            case 'filter':
                return <FilterOverlay content={content} contentRef={contentRef} />
            case 'designer':
                return <DesignerOverlay content={content} contentRef={contentRef} />
            case 'axis':
                return <AxisOverlay content={content} contentRef={contentRef} />
            default:
                break;
        }
    })(type, content, contentRef)

    return <>
        <ScrollBox open={isOpen} height={containerHeight}>
            <Container open={isOpen}>
                { !isMobile ? <Blackout onClick={closeOverlay} /> : null }
                <Content isMobile={isMobile} height={contentHeight}>
                    {contentBlock}
                </Content>
                <CloseButton onClick={closeOverlay} isMobile={isMobile}>&times;</CloseButton>
            </Container>
        </ScrollBox>
    </>
}