const sheet = {
    // public url to the google sheets; #gid=0 is required
    url: 'https://docs.google.com/spreadsheets/d/19YkI78ZjOkYL0T3K6Gk8bp-XDLtNVJobSECx6g5iMJE/edit#gid=0',
    // which fields should be selected
    query: 'select *',
    // google sheet to variable mapping. duplicate fields will be merged, and current/future fields will be split based on the visual toggle
    mapping: {
        0: 'date',
        1: 'email',
        2: 'name',
        3: 'organisation',
        // theme
        4: 'theme',
        5: 'theme',
        6: 'theme',
        // work
        7: 'current_work',
        8: 'future_work',
        // roles
        9: 'current_roles',
        10: 'future_roles',
        // values
        11: 'current_values',
        12: 'future_values',
        // experience
        13: 'experience',
        // involvement
        14: 'current_involvement',
        15: 'future_involvement',
        // reach
        16: 'current_reach',
        17: 'future_reach',
        // duration
        18: 'current_duration',
        19: 'future_duration',
        // initiative
        20: 'current_initiative',
        21: 'future_initiative',
        // perspective
        22: 'current_perspective',
        23: 'future_perspective',
    }
}
// keys
const keys = {
    experience: 'experience',
    involvement: 'involvement',
    reach: 'reach',
    duration: 'duration',
    initiative: 'initiative',
    perspective: 'perspective',
    theme: 'theme',
    work: 'work',
    roles: 'roles',
    values: 'values',
}
// labels per key
const labels = {
    [keys.experience]: 'Werkervaring',
    [keys.involvement]: 'Betrokkenen',
    [keys.reach]: 'Bereik',
    [keys.duration]: 'Duur',
    [keys.initiative]: 'Aanleiding',
    [keys.perspective]: 'Perspectief',
    [keys.theme]: 'Themas',
    [keys.work]: 'Werkvorm',
    [keys.roles]: 'Rollen',
    [keys.values]: 'Waarden',
}
// keys that represent scale values
const scales = [
    keys.experience,
    keys.involvement,
    keys.reach,
    keys.duration,
    keys.initiative,
    keys.perspective
]
// keys that represent properties
const properties = [
    keys.theme,
    keys.work,
    keys.roles,
    keys.values,
]
// ranges for scales
const ranges = {
    [keys.experience]: ['1 jaar', '20+ jaar'],
    [keys.involvement]: ['1', '10.000'],
    [keys.reach]: ['10', '10.000.000'],
    [keys.duration]: ['Enkele weken', '5+ jaar'],
    [keys.initiative]: ['Eigen initiatief', 'In opdracht'],
    [keys.perspective]: ['Praktijk', 'Academische kant'],
}
// axes based on previously labels, scales and ranges
const axesOptions = [
    { 
        range: ranges[keys.experience],
        label: labels[keys.experience], value: keys.experience, tooltip: true,
        description: 'Social design is een jong veld bestaande uit zowel doorgewinterde social designers, die ervaring en kennis brengen als ook nieuwkomers, die op hun beurt juist nieuwe ideeën en een andere manier van aanpak brengen. Op deze schaal is daarom te zien hoeveel jaar werkervaring iemand heeft als social designer.'
    },
    {
        range: ranges[keys.involvement],
        label: labels[keys.involvement], value: keys.involvement, tooltip: true,
        description: 'Een project kan volledig door de designer uitgevoerd worden of er kan juist een grote groep stakeholders en eindgebruikers in het ontwerpproces betrokken worden. Deze schaal visualiseert daarom het aantal mensen die de verschillende designers doorgaans in hun proces betrekken. Met betrokkenen wordt in dit geval zowel stakeholders, co-designers, experts en eindgebruikers bedoeld.'
    },
    {
        range: ranges[keys.reach],
        label: labels[keys.reach], value: keys.reach, tooltip: true,
        description: 'Uiteindelijk levert een project iets op waarmee er een bepaalde groep mensen wordt bereikt. Dit kan variëren van een bepaalde groep ouderen tot alle mensen die naar BBC kijken. Deze schaal visualiseert de hoeveelheid mensen die de verschillende social designers met hun projecten bereiken.        '
    },
    {
        range: ranges[keys.duration],
        label: labels[keys.duration], value: keys.duration, tooltip: true,
        description: 'De gemiddelde duur van een project kan sterk variëren van persoon tot persoon. Deze schaal visualiseert daarom de gemiddelde duur van een project uitgevoerd door social designers. Hierbij wordt projectduur beschouwd als de tijd die zit tussen het initiëren en het uiteindelijk opleveren en overdragen van een project.'
    },
    {
        range: ranges[keys.initiative],
        label: labels[keys.initiative], value: keys.initiative, tooltip: true,
        description: 'Een project kan in het leven geroepen worden als eigen initiatief of kan juist gestart worden doordat er een opdracht binnenkomt vanuit een externe organisatie. Deze schaal brengt de verhouding in kaart van zelf-geïnitieerde projecten en projecten in opdracht.'
    },
    {
        range: ranges[keys.perspective],
        label: labels[keys.perspective], value: keys.perspective, tooltip: true,
        description: 'Elk project kan met verschillende perspectieven worden ingevlogen. Dit kan zijn door volledig uit te gaan van de praktijk, observaties en gesprekken. Of door een academisch perspectief aan te nemen en te starten vanuit de literatuur en theoretische modellen. Deze schaal geeft aan vanuit welk perspectief de verschillende social designers hun projecten doorgaans benaderen.',
    },
]

// colour mapping of label values (visual only)
const colours = {
    'Disruptor': '#518C4E',
    'Prototyper': '#F3BB93',
    'Reframer': '#E5A7E8',
    'Onderzoeker': '#F3CD2E',
    'Verbinder': '#8A5F19',
    'Belangenbehartiger': '#0CDAC6',
    'Maker': '#91CE77',
    'Kartrekker': '#003EFF',
    'Conceptontwerper': '#EF543A',
    'Procesfacilitator': '#593BB5',
    'Responsiveness': '#593BB5',
    'Resilience': '#518C4E',
    'Care': '#EF543A',
    'Political progress': '#0CDAC6',
    'Social capital': '#F3CD2E',
    'Private sector': '#EF543A',
    'Ontwerpstudio': '#593BB5',
    'Zelfstandig': '#0CDAC6',
    '(Semi-)Overheid': '#F3CD2E',
    'Onderwijsinstelling': '#518C4E',
}
// filter options
const filters = [
    {
        // filter by key
        key: keys.roles,
        label: labels[keys.roles],
        // open by default
        open: true,
        tooltip: true,
        summary: 'Een ontwerper kan verschillende rollen aannemen in een ontwerpproces. Zij kan partijen verbinden, als een kartrekker fungeren of juist disruptie veroorzaken. Dit label visualiseert de rollen die iemand het vaakst aanneemt in een social design traject.',
        description: 'De verschillende rollen zijn als volgt gedefinieerd: ',
        options: [
            { label: 'Conceptontwerper', value: 'Conceptontwerper', color: '#EF543A', image: '/roles/Conceptontwerper.png', description: 'De Conceptontwerper vertaalt de belangrijke inzichten die in de context van het vraagstuk ontdekt zijn naar een of meerdere concrete concepten, zodanig dat het vervolgens uitgewerkt en gematerialiseerd kan worden.' },
            { label: 'Prototyper', value: 'Prototyper', color: '#F3BB93', image: '/roles/Prototyper.png', description: 'De Prototyper creëert prototypes — laagdrempelige studiemodellen — om daarmee aannames te toetsen en eerste ideeën aan een doelgroep voor te leggen.' },
            { label: 'Verbinder', value: 'Verbinder', color: '#8A5F19', image: '/roles/Verbinder.png', description: 'De Verbinder legt in concrete zin lijnen tussen verschillende mensen: stakeholders, collega’s en (eind-)gebruikers.' },
            { label: 'Onderzoeker', value: 'Onderzoeker', color: '#F3CD2E', image: '/roles/Onderzoeker.png', description: 'De Onderzoeker brengt de context en de doelgroep(en) in kaart door dit grondig te onderzoeken.' },
            { label: 'Disruptor', value: 'Disruptor', color: '#518C4E', image: '/roles/Disruptor.png', description: 'De Disruptor denkt buiten de gebaande paden en heeft als rol om vastgeroeste denkbeelden te erkennen en te doorbreken.' },
            { label: 'Maker', value: 'Maker', color: '#91CE77', image: '/roles/Maker.png', description: 'De Maker werkt het bedachte concept uit, giet het in de passende vorm en bouwt het uiteindelijk.' },
            { label: 'Belangenbehartiger', value: 'Belangenbehartiger', image: '/roles/Belangenbehartiger.png', color: '#0CDAC6', description: 'De Belangenbehartiger verzamelt de belangen van stakeholders en neemt deze zo goed mogelijk mee in het proces.' },
            { label: 'Kartrekker', value: 'Kartrekker', color: '#003EFF', image: '/roles/Kartrekker.png', description: 'De Kartrekker zorgt ervoor dat het project aansluiting vindt om voortgezet of uitgerold te worden.' },
            { label: 'Procesfacilitator', value: 'Procesfacilitator', image: '/roles/Procesfacilitator.png', color: '#593BB5', description: 'De Procesfacilitator houdt het team scherp op het volgen van het afgesproken designproces.' },
            { label: 'Reframer', value: 'Reframer', color: '#E5A7E8', image: '/roles/Reframer.png', description: 'De Reframer zorgt ervoor dat initiële kaders worden losgelaten door nieuwe perspectieven op het vraagstuk in te brengen. ' },
        ]
    },
    {
        key: keys.values,
        label: labels[keys.values],
        open: false,
        tooltip: true,
        summary: 'Social designers ontwerpen voor publieke waarden. Er zijn echter vele verschillende waarden om voor te ontwerpen. Dit label geeft de waarden aan die het meest terugkomen in de projecten die de verschillende social designers uitvoeren. De waarden zijn opgedeeld in vijf hoofdwaarden waarvoor je ontwerpt zoals voorgesteld door Tromp & Vial (2020):',
        options: [
            { label: 'Care', value: 'Care', color: '#EF543A', description: 'Activiteiten die als doel hebben om het welzijn en de levenskwaliteit te verbeteren van kansarme mensen.' },
            { label: 'Responsiveness', value: 'Responsiveness', color: '#593BB5', description: 'Het vergroten van kwaliteit en capaciteiten van de service van NGOs en overheidsorganisaties. Social design is hierbij gericht op de organisatie zelf (en niet op hun doelgroep).' },
            { label: 'Political progress', value: 'Political progress', color: '#0CDAC6', description: 'Activiteiten die vrijheidsbeperkingen en ongelijkheden bestrijden door mensen tools aan te reiken die hen helpen hun eigen machtsstructuren te verbeteren.  ' },
            { label: 'Social capital', value: 'Social capital', color: '#F3CD2E', description: 'Activiteiten die nieuwe vormen van samenwerken aanjagen met als doel om gemeenschappen te verbeteren en daarmee de relaties tussen mensen.' },
            { label: 'Resilience', value: 'Resilience', color: '#518C4E', description: 'Het bevorderen van systemische verandering voor een betere toekomst op de lange termijn. Het is minder gefocust op direct resultaat maar zet vooral veranderingen in gang voor de langere termijn.' },
        ]
    },
    {
        key: keys.work,
        label: labels[keys.work],
        open: false,
        tooltip: true,
        summary: 'Dit label geeft wat de voornaamste vorm is waarin de verschillende social designers hun werk verrichten. Het kan zijn dat iemand werkzaam is als social designer in meerdere vormen, dit label toont echter alleen de voornaamste vorm.',
        options: [
            { label: 'Private sector', value: 'Private sector', color: '#EF543A', description: 'Hiermee worden alle instanties bedoeld die in de vrije markt functioneren en geen ontwerpstudio zijn. De ontwerper werkt aan het ontwikkelen van producten of diensten.' },
            { label: 'Ontwerpstudio', value: 'Ontwerpstudio', color: '#593BB5', description: 'Dit is een bedrijf van meer dan één persoon waar voornamelijk ontwerpers werkzaam zijn.' },
            { label: 'Zelfstandig', value: 'Zelfstandig', color: '#0CDAC6', description: 'Dit is een ontwerper die als ZZP’er staat ingeschreven bij de Kamer van Koophandel.' },
            { label: '(Semi-)Overheid', value: '(Semi-)Overheid', color: '#F3CD2E', description: 'Dit is een organisatie die (voor een deel) draait op publieke gelden. De organisatie heeft geen winstoogmerk. ' },
            { label: 'Onderwijsinstelling', value: 'Onderwijsinstelling', color: '#518C4E', description: 'Dit zijn alle instanties die onderwijs en/of publieke kenniscreatie als belangrijkste doel hebben.' },
        ]
    },
]
// default active key for filterng
const defaultKey = keys.roles
const axe = {
    y: {
        options: axesOptions,
        default: keys.experience,
    },
    x: {
        options: axesOptions,
        default: keys.duration,
    },
    information: {
        options: axesOptions,
        label: 'Title',
        description: 'These are the various axe options.',
        summary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
    }
}

export {
    sheet,
    keys,
    labels,
    colours,
    scales,
    properties,
    ranges,
    filters,
    defaultKey,
    axe
}