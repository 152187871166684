import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { labels, ranges } from '../../config';
import { PieChart } from 'react-minimal-pie-chart'
import { colours } from '../../config'

const Container = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    z-index: -1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Axis = styled.text`
    font-family: Gilroy-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.isMobile ? '12px' : '16px'};
    color: #;
`
const Scale = styled.text`
    font-family: Gilroy-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.isMobile ? '12px' : '16px'};
    color: white;
`

const Dot = styled.circle`
    cursor: pointer;
`

export default function Map({ x, y, active, activeKey, activeFilters, shadows, handleClick, parentRef, ...props }) {
    const padding = isMobile ? 30 : 100;

    const size = (isMobile ? parentRef.current?.clientWidth : parentRef.current?.clientHeight) || 0
    const containerWidth = parentRef.current?.getBoundingClientRect().width || 0
    const containerHeight = parentRef.current?.getBoundingClientRect().height || 0

    return <Container height={containerHeight} width={containerWidth}>
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">

            <line x1={padding} y1={size / 2} x2={size - padding} y2={size / 2} stroke="white" strokeWidth={3} strokeDasharray={'5,5'}/>

            { isMobile
            ? <line x1={size / 2} y1={padding + 20} x2={size / 2} y2={size - padding} stroke="white" strokeWidth={3} strokeDasharray={'5,5'}/>
            : <line x1={size / 2} y1={padding} x2={size / 2} y2={size - padding} stroke="white" strokeWidth={3} strokeDasharray={'5,5'}/>
            }
            

            <Axis x={size- padding} y={(size / 2) + 30} fill={'#FD5135'} textAnchor={'end'} isMobile={isMobile}>{labels[x]}</Axis>
            <Scale x={padding} y={(size / 2) - 20} fill={'white'} isMobile={isMobile}>{ranges[x][0]}</Scale>
            <Scale x={size - padding} y={(size / 2) - 20} fill={'white'} textAnchor={'end'} isMobile={isMobile}>{ranges[x][1]}</Scale>

            { isMobile
            ? <>
                <Axis x={(size / 2)} y={16} fill={'#FD5135'} textAnchor={'middle'} isMobile={isMobile}>{labels[y]}</Axis>
                <Scale x={(size / 2)} y={40} fill={'white'} textAnchor={'middle'} isMobile={isMobile}>{ranges[y][1]}</Scale>
                <Scale x={(size / 2)} y={size - 10} fill={'white'} textAnchor={'middle'} isMobile={isMobile}>{ranges[y][0]}</Scale>
            </>
            : <>
                <Axis x={(size / 2) + 20} y={16 + padding} fill={'#FD5135'} isMobile={isMobile}>{labels[y]}</Axis>
                <Scale x={(size / 2) - 20} y={16 + padding} fill={'white'} textAnchor={'end'} isMobile={isMobile}>{ranges[y][1]}</Scale>
                <Scale x={(size / 2) - 20} y={size - padding} fill={'white'} textAnchor={'end'} isMobile={isMobile}>{ranges[y][0]}</Scale>
            </>
            }


            {shadows.map((designer, i) =>
                <Dot
                    cx={(size - padding * 2) * designer[x] + padding}
                    cy={(size - padding * 2) * (1 - designer[y]) + padding}
                    r={7}
                    fill={'rgba(255,255,255,0.2)'}
                    onClick={() => handleClick(designer)}
                    key={i}
                />
            )}

            {active.map((designer, i) => {
                if (!Array.isArray(designer[activeKey])) {
                    designer[activeKey] = [designer[activeKey]]
                }
                let data = designer[activeKey] 
                    ? designer[activeKey].map(item => ({ title: item, value: 1, color: colours[item] }))
                    : []
                
                if (activeFilters[activeKey] && activeFilters[activeKey].length) {
                    data = data.filter(item => activeFilters[activeKey].includes(item.title))
                }

                return <PieChart
                    radius={7}
                    center={[
                        (size - padding * 2) * designer[x] + padding,
                        (size - padding * 2) * (1 - designer[y]) + padding,
                    ]}
                    viewBoxSize={[size, size]}
                    data={data.slice(0, 3)}
                    onClick={() => handleClick(designer)}
                    key={designer.name}
                    style={{ cursor: 'pointer' }}
                />
            })}

        </svg>
    </Container>
}