import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import { labels, properties, scales, ranges } from '../../../config'

import Scale from '../../Scale'

const Container = styled.div`
    display: ${props => props.isMobile ? 'block' : 'flex'};
    height: 100%;
`

const InfoContainer = styled.div`
    width: calc(50% - 200px);
    padding: 100px;
    ${props => props.isMobile && `
        padding: 40px 40px 0;
        width: calc(100% - 80px);
    `}
    h4 {
        color: #FD5135;
        font-family: Gilroy-bold;
        font-size: 30px;
        margin-bottom: 25px;
    }
    h3 {
        color: #0B0031;
        font-family: Gilroy-Bold;
        font-size: 30px;
        margin-bottom: 15px;
    }
    em {
        color: #0B0031;
        font-family: Gilroy-Regular;
        font-size: 20px;
        margin-bottom: 50px;
        display: block;
    }
`
const ScaleContainer = styled.div`
    width: calc(50% - 10%);
    padding: 100px 10%;
    ${props => props.isMobile && `
        width: calc(100% - 50px);
        padding: 25px;
    `}
    background: #F1F2F3;
`

const InfoBlock = styled.div`
    border-top: 1px solid #B5B5B5;
    padding: 30px 0 25px;
    display: flex;
    justify-content: space-between;
    span {
        display: block;
        font-family: Gilroy-bold;
        font-size: 15px;
        color: #0B0031;
        display: block;
        margin-right: 30px;
        width: 25%;
    }
    ul {
        width: calc(75% - 30px);
        display: block;
    }
    li:not(:last-child) {
        margin: 0 0 20px;
    }
    li {
        color: #0B0031;
        font-family: Gilroy-Regular;
        font-size: 20px;
    }
`

const ScaleBlock = styled.div`
    border-bottom: 1px solid #B5B5B5;
    padding: 30px 0;
    em {
        font-family: Gilroy-bold !important;
        font-size: 15px !important;
        line-height: 18px;
        color: #0B0031;
    }
`

export default function DesignerOverlay({ contentRef, content, ...props }) {
    const { name, organisation } = content

    const infoBlocks = properties.map((key, i) => {
        const entries = Array.isArray(content[key]) ? content[key] : [content[key]]
        return <InfoBlock key={i}>
                <span>{labels[key]}</span>
                <ul>
                    {entries.map((item, j) => <li key={j}>{item}</li>)}
                </ul>
            </InfoBlock>
    })

    const scaleBlocks = scales.map((key, i) => {
        const entry = content[key]
        const entryLabel = ranges[key]
        return <ScaleBlock key={i}>
                <em>{labels[key]}</em>
                <Scale from={entryLabel[0]} to={entryLabel[1]} progress={entry} />
            </ScaleBlock>
    })

    return <Container isMobile={isMobile}>
        <InfoContainer isMobile={isMobile}>
            <h4>Profiel</h4>
            <h3>{name}</h3>
            <em>{organisation}</em>
            {infoBlocks}

        </InfoContainer>
        <ScaleContainer ref={contentRef} isMobile={isMobile}>
            {scaleBlocks}
        </ScaleContainer>
    </Container>
}