import sheetrock from 'sheetrock'
import { Fragment, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Reset } from 'styled-reset'
import { isMobile } from 'react-device-detect'
import './App.css'

import ZoomableView from './components/ZoomableView'
import Dropdown from './components/Filter/Dropdown'
import Filter from './components/Filter'
import Map from './components/Map'
import Overlay from './components/Overlay'

import applyFilter from './reducers/apply-filter'
import formatRawData from './reducers/format-raw-data'
import processRawData from './reducers/process-raw-data'
import filterForPrefix from './reducers/filter-for-prefix'

import { defaultKey, sheet, filters, axe } from './config'

import { Filters } from './icons'
import Toggle from './components/Toggle'


const Heading = styled.div`
  padding: 25px;
  border-bottom: 1px solid white;
  color: #FD5135;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-family: 'Gilroy-bold';
    font-weight: bold;
    flex-grow: 1;
    display: inline-block;
    margin-left: 20px;
    font-size: 26px;
    padding: 9px 0;
  }
`

const Button = styled.button`
  background: none;
  border: 1px solid white;
  border-radius: 50px;
  padding: 10px 15px 8px;
  color: white;
  cursor: pointer;
  line-height: 1;
  font-size: 16px;

  font-family: 'Gilroy-Regular';
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`


const Container = styled.div`
  // height: ${props => props.isMobile ? '90vh' : '100vh'};
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
`
const Content = styled.div`
  background: #07003A;
  flex-grow: 1;
  position: relative;
`
const Sidebar = styled.div`
  height: 100%;
  width: 25%;
  min-width: 400px;
  background: #211a4e;
  overflow: scroll;
  font-family: 'Gilroy-Regular';
  // z-index: 99999;
  user-select: none;
  ${props => !props.isVisible && `
    display: none;
  `}
  ${props => props.isMobile && `
    width: 100%;
    min-width: 0;
    position: fixed;
  `}
`
const SidebarCloseButton = styled.span`
  font-size: 40px;
  color: white;
  margin-left: 20px;
`

const FilterButton = styled.button`
  border: 1px solid white;
  border-radius: 100%;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  background: none;
  z-index: 9999;
  svg {
    position: relative;
    top: 2px;
  }
`

const ActiveLabel = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
  font-family: 'Gilroy-Regular';
  background: #07003A;
  ${props => props.isMobile && `
    top: 30px;
    left: 30px;
    span {
      font-size: 16px !important;
    }
    h2 {
      font-size: 20px !important;
      margin-top: 10px !important;
    }
  `}
  span {
    // text-transform: uppercase;
    font-size: 23px;
    color: white;
  }
  h2 {
    font-weight: bold;
    font-size: 30px;
    color: #FD5135;
    margin-top: 20px;
  }
`


const ScrollHandle = styled.div`
  width: 100%;
  height: 25%;
  position: absolute;
  ${props => props.bottom ? 'bottom' : 'top'}: 0;
  left: 0;
  z-index: 100;
`

function App({ ...props }) {
  const containerRef = useRef()

  const [data, setData] = useState([])
  useEffect(() => {
    const callback = (err, opts, res) => {
      if (err) return
      setData(
        processRawData(formatRawData(res, sheet.mapping))
      )
    }
    sheetrock({ ...sheet, callback })
  }, [])

  const [isFutureData, setIsFutureData] = useState(false)
  const [activeKey, setActiveKey] = useState(defaultKey)
  const defaultFilters = {
    x: axe.x.default,
    y: axe.y.default,
  }
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [filterRules, setFilterRules] = useState(defaultFilters)
  const resetFilters = () => {
    setFilterRules(defaultFilters)
    setFiltersChanged(false)
    setIsFutureData(false)
  }


  const clearFilter = (key) => setFilterRules({ ...filterRules, [key]: undefined })
  const onSelect = (key, option) => {
    setFilterRules({ ...filterRules, [key]: option.value })
    setFiltersChanged(true)
  }

  const onMultiSelect = (key, option) => {
    const filterRule = filterRules[key] || []
    if (option !== undefined) {
      if (filterRule.includes(option.value)) {
        filterRule.splice(filterRule.indexOf(option.value), 1)
      } else {
        filterRule.push(option.value)
      }
    }
    const { x, y } = filterRules
    setActiveKey(key)
    if (filterRule.length) {
      setFilterRules({ x, y, [key]: filterRule })
    } else {
      setFilterRules({ x, y })
    }
    setFiltersChanged(true)
  }

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filtered = Object
      .entries(filterRules)
      .filter(([key, value]) => !['x', 'y'].includes(key))
      .reduce((prev, curr) =>
        applyFilter(prev, { key: curr[0], filter: curr[1] })
      , filterForPrefix(data, isFutureData ? 'future' : 'current'))
    setFilteredData(filtered)
  }, [data, filterRules, isFutureData])


  const [overlayOpen, setOverlayOpen] = useState(false)
  const [overlayType, setOverlayType] = useState('filter')
  const [overlayContentData, setOverlayContentData] = useState({})
  const setOverlayContent = (type, filter) => {
    setOverlayOpen(true)
    setOverlayType(type)
    setOverlayContentData(filter)
  }

  const [sidebarVisible, setSidebarVisible] = useState(!isMobile)

  const activeLabel = filters.find(f => f.key === activeKey)?.label

  const mapRef = useRef()


  return (
    <Container ref={containerRef} isMobile={isMobile}>
      <Reset />

      { isMobile && !sidebarVisible
      ? <FilterButton onClick={() => setSidebarVisible(true)}>
          <Filters fill={'white'}/>
        </FilterButton>
      : null }

      <Overlay containerRef={containerRef} content={overlayContentData} type={overlayType} open={overlayOpen} onClose={() => setOverlayOpen(false)} />

      <Content ref={mapRef}>
        { isMobile && !sidebarVisible ? <ScrollHandle /> : null }
        { isMobile && !sidebarVisible ? <ScrollHandle bottom={true} /> : null }
        <ActiveLabel isMobile={isMobile}>
          <span>Label</span>
          <h2>{activeLabel}</h2>
        </ActiveLabel>
        <ZoomableView>
          <Map
            x={filterRules.x}
            y={filterRules.y}
            shadows={filterForPrefix(data, isFutureData ? 'future' : 'current')}
            active={filteredData}
            parentRef={mapRef}
            activeKey={activeKey}
            activeFilters={filterRules}
            handleClick={designer => setOverlayContent('designer', designer)}
          />
        </ZoomableView>
        <Toggle fromLabel={'Nu'} toLabel={'Toekomst'} active={isFutureData} onSwitch={isFuture => setIsFutureData(isFuture)} />
      </Content>

      <Sidebar isVisible={sidebarVisible} isMobile={isMobile}>

        <Heading>
          <Filters fill={'#FD5135'} />
          <h2>Filters</h2>
          {filtersChanged ? <Button onClick={resetFilters}>Wis alle filters</Button> : null }
          { isMobile
          ? <SidebarCloseButton onClick={() => setSidebarVisible(false)}>&times;</SidebarCloseButton>
          : null
          }
        </Heading>

        <Dropdown label={'Y-AS'} value={axe.y.options.find(opt => opt.value === filterRules.y).label}>
          <Filter options={axe.y.options} selected={filterRules.y} disabled={filterRules.x} onSelect={option => onSelect('y', option)} onInfo={option => setOverlayContent('axis', option)} />
        </Dropdown>

        <Dropdown label={'X-AS'} value={axe.x.options.find(opt => opt.value === filterRules.x).label}>
          <Filter options={axe.x.options} selected={filterRules.x} disabled={filterRules.y} onSelect={option => onSelect('x', option)} onInfo={option => setOverlayContent('axis', option)} />
        </Dropdown>

        <Dropdown label={'Labels'} open={filters.filter(f => f.open).length > 0} last={true} value={activeLabel}>
          {filters.map((filter, i) => 
            <Fragment key={i}>
              <Dropdown
                label={filter.label}
                tooltip={filter.tooltip}
                onClear={filterRules[filter.key]?.length ? () => clearFilter(filter.key) : null}
                nested={true}
                open={filter.label === activeLabel}
                onInfo={() => setOverlayContent('filter', filter)}
                onOpen={() => onMultiSelect(filter.key)}
              >
                <Filter
                  options={filter.options}
                  selected={filterRules[filter.key]}
                  onSelect={option => onMultiSelect(filter.key, option)}
                  onInfo={() => setOverlayContent('filter', filter) }
                  softDisabled={
                    activeKey === filter.key
                    ? filterRules[filter.key]?.length && filter.options
                        .filter(f => !filterRules[filter.key]?.includes(f.value))
                        .map(f => f.value)
                    : (Object.keys(filterRules).length >= 3
                      ? filters.find(f => f.key === filter.key)?.options.map(f => f.value)
                      : []
                    )
                  }
                />
              </Dropdown>
            </Fragment>
          )}
        </Dropdown>

      </Sidebar>

    </Container>
  );
}

export default App;
