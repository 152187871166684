export default function formatRawData(data, mapping) {
    return data.rows
      .slice(1)
      .map(row => row.cellsArray)
      .map(cells => {
        const obj = {}
        cells.map((value, i) => {
          if (!value.length) return null
          const key = mapping[i]

          if (value.includes(',')) {
            value = value.split(', ')
          }

          if (obj.hasOwnProperty(key)) {
            if (!Array.isArray(obj[key])) {
              obj[key] = [obj[key]]
            }
            if (Array.isArray(value)) {
              obj[key] = obj[key].concat(value)
            } else {
              obj[key].push(value)
            }
          } else {
            obj[key] = value
          }
          return value
        })
        return obj
      })
}