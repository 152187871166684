import React from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  color: white;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  border: 1px solid white;
  border-radius: 50px;
  text-align: center;
  padding: 3px;
`

const Button = styled.button`
  display: block;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  margin: 0;
  padding: 10px 0;
  transition: background .2s;
  ${props => props.top && `
    border-radius: 50px 50px 0 0;
  `}
  ${props => props.bottom && `
    border-radius: 0 0 50px 50px;
  `}
  width: 30px;
  &:hover {
    background: rgba(255, 255, 255, .1);
  }
`

const Separator = styled.span`
  border-bottom: 1px solid white;
  display: block;
  width: 100%;
`


export default function ZoomableView({ children }) {
  return (
    <TransformWrapper wheel={{ disabled: true }}>
      {({ zoomIn, zoomOut }) => (
        <Container>
          <TransformComponent>
            {children}
          </TransformComponent>
          { !isMobile ?
            <ButtonContainer>
              <Button onClick={zoomIn} top>+</Button>
              <Separator />
              <Button onClick={zoomOut} bottom>–</Button>
            </ButtonContainer>
          : null }
        </Container>
      )}
    </TransformWrapper>
  )
}