import styled from 'styled-components'
import { useRef } from 'react'
import { isMobile } from 'react-device-detect'

const ToggleContainer = styled.div`
    position: absolute;
    z-index: 101;
    ${props => props.isMobile
    ? `
        bottom: 50px;
        left: calc(50% - ${props.width / 2.5}px);
    `
    : `
        top: 50px;
        right: 50px;
    `}
    display: flex;
    align-items: center;
`


const ToggleLabel = styled.span`
    color: white;
    font-family: Gilroy-Regular;
    font-size: 12px;
    line-height: 14px;
`

const ToggleBody = styled.span`
    border: 1px solid white;
    border-radius: 50px;
    height: 25px;
    width: 50px;
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    background: #07003A;
`

const ToggleClicker = styled.span`
    height: 34px;
    width: 34px;
    border-radius: 100%;
    display: inline-block;
    background: white;
    position: relative;
    top: -4px;
    left: -4px;
    float: left;
    transition: float .25s;
    ${props => props.active && `
        float: right;
        left: 4px;
    `}
`

export default function Toggle({ fromLabel, active, toLabel, onSwitch, ...props }) {
    const containerRef = useRef()
    const containerWidth = containerRef.current?.getBoundingClientRect().width || 0

    return <ToggleContainer isMobile={isMobile} ref={containerRef} width={containerWidth}>
        <ToggleLabel>{fromLabel}</ToggleLabel>
        <ToggleBody onClick={() => onSwitch(!active)}>
            <ToggleClicker active={active} />
        </ToggleBody>
        <ToggleLabel>{toLabel}</ToggleLabel>
    </ToggleContainer>
}