import _ from 'lodash'

export default function applyFilter(data, { key, filter }) {
    return data
        .filter(obj => {
            const value = obj[key]
            if (Array.isArray(value)) {
                return _.intersection(value, filter).length > 0
            } else {
                return filter.includes(value)
            }
        })
}