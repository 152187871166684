import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import Scale from '../../Scale'

const Container = styled.div`
    padding: ${props => props.isMobile ? '40px 30px' : '50px' };
    p {
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 75px;
        margin-bottom: 75px;
    }
`

export default function AxisOverlay({ content, contentRef }) {
    const { label, description, range } = content
    return <Container ref={contentRef} isMobile={isMobile}>
        <h2>{label}</h2>
        <p>{description}</p>
        <Scale from={range[0]} to={range[1]} />
    </Container>
}