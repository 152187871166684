import Option from './Option'

export default function Filter({ options, selected, softDisabled, disabled, onSelect, onReset, onInfo, ...props}) {
    const filterOptions = options.map((option, i) => {
        const isSelected = selected
            ? (Array.isArray(selected)
                ? selected.includes(option.value)
                : option.value === selected
            )
            : false
        
        const isDisabled = disabled
            ? (Array.isArray(disabled)
                ? disabled.includes(option.value)
                : option.value === disabled
            )
            : false
        
        const isSoftDisabled = softDisabled
            ? (Array.isArray(softDisabled)
                ? softDisabled.includes(option.value)
                : option.value === softDisabled
            )
            : false

        return (
            <div onClick={!isDisabled ? () => onSelect(option) : null} key={i}>
                <Option {...option} selected={isSelected} disabled={isDisabled || isSoftDisabled} onInfo={() => onInfo(option)} />
            </div>
        )
    })
    return <>{filterOptions}</>
}