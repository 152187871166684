import { useState, useEffect } from "react"
import styled from 'styled-components'
import { Chevron, InfoBubble } from "../../../icons"

const Dropdown = styled.div `
    ${props => props.nested && `
        margin-left: -15px;
    `}
    ${props => props.open && !props.nested && !props.last && `
        border-bottom: 1px solid white;
    `}
`
const Content = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s;
    transition: padding .05s;
    padding: 0 0 0 50px;
    vertical-align: baseline;
    ${props => props.open && `
        max-height: 100%;
        padding: 10px 0 10px 50px;
    `}
`
const Label = styled.div `
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 10px;
    ${props => !props.nested && `
        border-bottom: 1px solid white;
    `}
    ${props => props.nested && `
        padding: 10px 30px 10px 10px;
    `}
`

const Arrow = styled.span`
    color: white;
    transition: transform .25s;
    margin-right: 10px;
    transform: rotate(-90deg);
    ${props => props.open && `
        transform: rotate(0deg);
    `}
`

const Heading = styled.h4`
    font: 25px 'Gilroy-bold';
    margin: 0 25px 0 0;
    flex-grow: 2;
    align-items: center;
    display: flex;
    span {
        margin-right: 15px;
    }
`
const Value = styled.p`
    opacity: .75;
    font: 20px 'Gilroy-Regular';
`

export default function FilterDropdown({ label, value, open, tooltip, children, nested, last, onOpen, onClear, onInfo, ...props }) {
    const [isOpen, setIsOpen] = useState(open !== undefined ? open : false)
    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
        if (onOpen) onOpen()
    }

    useEffect(() => {
        setIsOpen(open)
    }, [open])
    
    return (
        <Dropdown nested={nested} open={isOpen} last={last}>
            <Label nested={nested} onClick={toggleIsOpen}>
                <Arrow open={isOpen}><Chevron /></Arrow>
                <Heading>
                    <span>{label}</span>
                    {tooltip ? <span onClick={onInfo}><InfoBubble /></span> : null }
                </Heading>
                {value ? <Value>{value}</Value> : null}
                {/* {onClear ? <Button onClick={onClear}>Wis filters</Button> : null} */}
            </Label>
            <Content open={isOpen}>
                {children}
            </Content>
        </Dropdown>
    )
}