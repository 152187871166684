export default function processRawData(data) {
    const scales = ['involvement', 'reach', 'duration', 'initiative', 'perspective']
    return data.map(row => {
        Object.entries(row).map(([key, value]) => {
            const [, component] = key.split('_')
            const jitter = (Math.random() / 50) * (Math.round(Math.random()) * 2 - 1)
            if (scales.includes(component)) {
                row[key] = parseInt(value) / 7 + jitter
            }
            if (key === 'experience') {
                row[key] = Math.min(1, parseInt(value) / 20) + jitter   
            }
            return null
        })
        return {
            ...row,
        }
    })
}