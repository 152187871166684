import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const Container = styled.div`
    padding: ${props => props.isMobile ? '40px 30px' : '50px' };
`

const CardContainer = styled.div`
    ${props => !props.isMobile && `
        display: flex;
        flex-wrap: wrap;
    `}
    margin-top: 50px;
`

const Card = styled.div`
    ${props => !props.isMobile && `
        flex: 0 0 calc(33% - 40px);
        max-width: calc(33% - 40px);
    `}
    border-top: 1px solid #B5B5B5;
    margin:  20px 20px 0;
    padding: 40px 0 0;
    div {
        display: flex;
        align-items: center;
    }
    img {
        width: 92px;
        height: 92px;
        border-radius: 100%;
        margin-right: 30px;
        display: inline-block;
    }
    h3 {
        font-size: 30px;
        line-height: 35px;
        font-family: Gilroy-bold;
        color: #0B0031;
        margin: 0;
        display: inline-block;
    }
    p {
        font-size: 16px;
        font-family: Gilroy-Regular;
        font-weight: normal !important;
    }
    ${props => props.isMobile && `
        margin: 0 !important;
        // padding: 20px 0 0;
        p {
            margin: 15px 0 0;
        }
        img {
            margin-right: 25px;
        }
    `}
`

export default function FilterOverlay({ content, contentRef }) {
    const { label, summary, description, options } = content
    return <Container ref={contentRef} isMobile={isMobile}>
        <h2>{label}</h2>
        <p>{summary}</p>
        <em>{description}</em>
        <CardContainer isMobile={isMobile}>
            {options?.map((option, i) => <>
                <Card isMobile={isMobile}>
                    <div>
                        {option.image ? <img src={option.image} alt={option.label} /> : null}
                        <h3>{option.label}</h3>
                    </div>
                    <p>{option.description}</p>
                </Card>
            </>)}
        </CardContainer>
    </Container>
}