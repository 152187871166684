function Chevron() {
    return <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3533 13.7677L17.6566 8.46446L18.3637 9.17157L11.9998 15.5355L5.63582 9.17154L6.34292 8.46443L11.6462 13.7677L11.9998 14.1213L12.3533 13.7677Z" fill="white" stroke="white"/>
        </svg>
    </>
}

function InfoBubble() {
    return <>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="9.5" r="8.5" stroke="white" strokeWidth="2"/>
            <path d="M9.00811 7.544C8.67211 7.544 8.38011 7.424 8.13211 7.184C7.89211 6.936 7.77211 6.648 7.77211 6.32C7.77211 5.992 7.89211 5.708 8.13211 5.468C8.38011 5.22 8.67211 5.096 9.00811 5.096C9.33611 5.096 9.62011 5.22 9.86011 5.468C10.1081 5.708 10.2321 5.992 10.2321 6.32C10.2321 6.648 10.1081 6.936 9.86011 7.184C9.62011 7.424 9.33611 7.544 9.00811 7.544ZM7.89211 14V8H10.1121V14H7.89211Z" fill="white"/>
        </svg>
    </>
}

function Filters({ fill }) {
    return <>
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7886 0.961504V3.5221C16.403 3.93843 15.3848 5.21153 15.3848 6.73074C15.3848 8.24994 16.403 9.5221 17.7886 9.93937V22.1154C17.7886 22.6462 18.2194 23.0769 18.7502 23.0769C19.2809 23.0769 19.7117 22.6462 19.7117 22.1154V9.93942C21.0972 9.52309 22.1155 8.24998 22.1155 6.73078C22.1155 5.21158 21.0972 3.93942 19.7117 3.52214V0.96155C19.7117 0.429834 19.2809 2.67029e-05 18.7502 2.67029e-05C18.2194 2.67029e-05 17.7886 0.429789 17.7886 0.961504ZM20.1925 6.73074C20.1925 7.52594 19.5454 8.17304 18.7502 8.17304C17.955 8.17304 17.3079 7.52594 17.3079 6.73074C17.3079 5.93553 17.955 5.28843 18.7502 5.28843C19.5454 5.28843 20.1925 5.93557 20.1925 6.73074Z" fill={fill}/>
            <path d="M2.40383 0.961504V3.5221C1.01827 3.93942 0 5.21153 0 6.73074C0 8.24994 1.01827 9.5221 2.40383 9.93942L2.40383 22.1154C2.40383 22.6462 2.83459 23.0769 3.36535 23.0769C3.89612 23.0769 4.32688 22.6462 4.32688 22.1154L4.32688 9.93942C5.71249 9.5221 6.73075 8.24998 6.73075 6.73074C6.73075 5.21149 5.71248 3.93937 4.32692 3.5221L4.32692 0.961504C4.32692 0.429789 3.89617 -1.90735e-05 3.3654 -1.90735e-05C2.83463 -1.90735e-05 2.40383 0.429789 2.40383 0.961504ZM4.80766 6.73074C4.80766 7.52594 4.16056 8.17304 3.36535 8.17304C2.57015 8.17304 1.92305 7.52594 1.92305 6.73074C1.92305 5.93553 2.57015 5.28843 3.36535 5.28843C4.16056 5.28843 4.80766 5.93557 4.80766 6.73074Z" fill={fill}/>
            <path d="M10.0962 0.961504L10.0962 13.1375C8.71065 13.5548 7.69238 14.8269 7.69238 16.3461C7.69238 17.8653 8.71065 19.1375 10.0962 19.5547V22.1154C10.0962 22.6462 10.527 23.0769 11.0577 23.0769C11.5885 23.0769 12.0193 22.6462 12.0193 22.1154V19.5548C13.4048 19.1375 14.4231 17.8654 14.4231 16.3462C14.4231 14.8269 13.4048 13.5548 12.0193 13.1375L12.0193 0.96155C12.0193 0.429834 11.5885 2.67029e-05 11.0577 2.67029e-05C10.527 -1.90735e-05 10.0962 0.429789 10.0962 0.961504ZM12.5001 16.3462C12.5001 17.1414 11.853 17.7885 11.0577 17.7885C10.2625 17.7885 9.61543 17.1414 9.61543 16.3462C9.61543 15.5509 10.2625 14.9038 11.0577 14.9038C11.853 14.9038 12.5001 15.5509 12.5001 16.3462Z" fill={fill}/>
        </svg>
    </>
}

export {
    Chevron,
    InfoBubble,
    Filters,
}